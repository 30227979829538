import React from "react";
import { AuthProvider } from "./components/firebase/Auth";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./components/Login/Login";

function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <Route exact path="/login" component={Login} />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
