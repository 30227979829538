/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext } from "react";
import firebase, { auth } from "../firebase/firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { withRouter, Redirect } from "react-router";
import { AuthContext } from "../firebase/Auth";

const Login = (history: any) => {
  const [isSignedIn, setIsSignedIn] = React.useState(false);
  React.useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });
  }, []);

  //   const handleLogin = useCallback(async () => {
  //     history.push("/");
  //   });

  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    uiCallback: {
      signInSuccess: () => history.push("/"),
    },
  };

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/pog" />;
  }

  return (
    <>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
    </>
  );
};

export default withRouter(Login);
